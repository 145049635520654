/**
 *@auther: tang
 @Date: 2020-10-31
 *Project: netbaseMusic
 */
import React from "react"

class EleTv extends React.Component {
    render() {
        return (
            <div>
                <h2>这是电台</h2>
            </div>
        )
    }
}

export {EleTv as default}
