/**
 *@auther: tang
 @Date: 2020-10-31
 *Project: netbaseMusic
 */
import React from "react"

class Community extends React.Component {
    render() {
        return (
            <div><h2>音乐社区</h2></div>
        )
    }
}

export {Community as default}
